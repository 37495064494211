/**
 * BatchGeoTrack is a set of utilities that proxies to event tracking services.
 * Currently that's just Google analytics but this allows us to make global
 * changes and if Google Analytics changes (which it does) or if we add or
 * change services we can just update it in one place.
 */
(function (window) {
	var TRACKING_ID = 'UA-104467-4';

	window.BatchGeoTrack = {
		/**
		 * Sends a tracking event in the proper format to Google Analytics in
		 * production and in dev will log out the event that happened. You must
		 * pass three properties on each event call: featureName, eventName, and
		 * eventValue.
		 *
		 * featureName is the feature you are tracking (like "heatmap").
		 * eventName is the event that happened (like "toggle"). eventValue can
		 * be anything. Use it to track details about how the event happened.
		 * In the case of a "toggle" it might be 1 or 0. In the case of "edit"
		 * maybe you send back the new string.
		 *
		 * @example
		 *
		 * window.BatchGeoTrack.event({
		 *   featureName: 'heatmap',
		 *   eventName: 'toggle',
		 *   eventValue: BatchGeoStore.getState().HeatMap.enabled
		 * })
		 *
		 * @param  {object} eventDetails An object with the featureName,
		 * evenName, and eventValue properties. Details for each of these above.
		 *
		 * @return {undefined}
		 */

		event: function (eventDetails) {
			try {
				if ([eventDetails.featureName, eventDetails.eventName, eventDetails.eventValue].indexOf(undefined) !== -1) {
					throw {message: 'Tracking event is missing data. Make sure you have a featureName, eventName, and eventValue.', event: eventDetails};
				}

				var eventObject = {
					event_action: eventDetails.eventName, // ex: "toggle"
					event_category: eventDetails.featureName, // ex: "advanced",
					value: eventDetails.eventValue, // ex: 0
					event_label: eventDetails.eventLabel !== undefined ? eventDetails.eventLabel : BatchGeo.getSessionId() // ex:
				}

				if (BatchGeoStore.getState().App.isDev) {
					console.log('Tracking Event: ', eventObject);
				} else {
					gtag('event', eventObject.event_action, {
						event_category: eventObject.event_category,
						value: eventObject.value,
						event_label: eventDetails.eventLabel !== undefined ? eventDetails.eventLabel : BatchGeo.getSessionId()
					});
				}

			} catch (error) {
				console.error(error.message, 'Event Data', error.event ? error.event : eventObject);
			}


		},

		/**
		 * Tracks synthetic page views with Google Analytics. This is the
		 * preferred way of sending the page tracking over raw gtag for a couple
		 * reasons:
		 *
		 * 1. When Analytics is updated we only need to update one spot
		 * (analytics has been updated 3 times since this project has began)
		 *
		 * 2. It will fix common errors for you such as forgetting the slash in
		 * your path.
		 *
		 * 3. You don't need to remember the weird syntax for it (config, pass
		 * the tracking code ID?!)
		 *
		 * @example
		 *
		 * // Just send the path, use default page title
		 * window.BatchGeoTrack.pageview('/conversion');
		 *
		 * // Same as before but now with a custom page title
		 * window.BatchGeoTrack.pageview('/conversion', 'Success');
		 *
		 * @param {string} path The synthetic path you want to track. For
		 * example, you could send `/conversion` to track signing up for a new
		 * Pro when the page doesn't actually change to anything.
		 *
		 * @param {string} [title=document.title] The "fake" page title you want
		 * to send. If you don't send anything it'll use the current page title.
		 *
		 * @return {undefined}
		 */
		
	}
})(window);
