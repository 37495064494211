require('./lib/jquery.min.js')
window._ = require('./lib/lodash.min.js')
window.Clusterize = require('./lib/clusterize.js')
require('./bg.polyfill.js')
require('./batchgeo-session/batchgeo-session.js')
require('./lib/colorbox.js')
require('./batchgeo/batchgeo.js')
require('./batchgeo/batchgeo-app-reducer.js')
require('./batchgeo-store/batchgeo-store.js')
require('./batchgeo-track/batchgeo-track.js')
require('./lib/linkify.min.js')
require('./batchgeo-strings/batchgeo-strings.js')
require('./batchgeo-devices/batchgeo-devices.js')
require('./batchgeo-api/batchgeo-api.js')
require('./batchgeo-access-view/batchgeo-access-view.js')
require('./batchgeo-persistence/batchgeo-persistence.js')
require('./bg.login.js')
