import * as Redux from 'redux'

(function () {
	/**
	 * BatchGeoStore (BGS) is a global store built on top of Redux
	 * (http://redux.js.org/). If you don't know Redux go to the site and learn
	 * the basics.
	 *
	 * BGS adds some helpers to make working with it a bit easier. Some of those
	 * features are:
	 *
	 * 1. Auto store creation. You never create the store, it exists on load.
	 *
	 * 2. BGS is the store and "library" so you can call BatchGeoStore.getState()
	 *    as well as BatchGeoStore.combineReducers()
	 *
	 * 3. It has a built in way of only firing events on state change. Redux's
	 * 		built-in subscribe() is low level and fires for every action. BGS has
	 * 		addListener() which only fires on changes.
	 *
	 * 4. It allows you to very easily break up your reducers across files with
	 *    BatchGeoStore.addReducer()
	 *
	 * Redux is mixed into BatchGeoStore so you can use any Redux method.
	 */
	// If BatchGeoStore is taken, exit early. If not, if you ever try to load
	// BatchGeoStore twice it'll wipe out state.
	if (window.BatchGeoStore) { return; };

	var BatchGeoStore = Object.assign({}, Redux, {
		// Where we store and track all the reducers
		_reducers: {
			// add our global app reducer here since one is required during Redux.createStore()
			App: window.BatchGeoAppReducer
		},

		/**
		 * Creates our initial store right during init
		 * @private
		 */
		_createStore: function () {
			this._store = Redux.createStore(Redux.combineReducers(this._reducers));
		},

		/**
		 * addReducer allows you to merge in a new reducer function with the "name"
		 * argument become the key of the reducer in the state object. The method
		 * allows for either a addReducer(reducerName, defaultState, actionHandlers)
		 * or addReducer(reducerName, reducer) signature.
		 *
		 * If you pass it a function as the 2nd argument it will run the reducer
		 * as is. No magic will happen. See Redux docs on reducers.
		 *
		 * However, if you pass an object (default state) as the 2nd argument then
		 * a action handler hash as the 3rd argument it will generate a reducer for
		 * you. Will fall back to returning global state if a type isn't handled.
		 *
		 * @param {string} name The key to use for the reducer in the state
		 * @param {object|function} defaultStateOrNewReducer Either the default
		 * state as an object or a reducer function.
		 * @param {object}[actionHandlingHash] A hash where the key is the action
		 * type to handle and the value is a handler function. It will give you
		 * state and action as arguments respectively. The state argument will be
		 * a clone of the state so you can mutate it. See 2nd example below.
		 *
		 * @example
		 * // Let's say current BatchGeoStore.getState() == {}
		 * BatchGeoStore.addReducer('MyReducer', function (state, action) {
		 *   // State here == undefined. The state you get is in the context of:
		 *   // BatchGeoStore.getState().MyReducer
		 *   state = state || {
		 *     count: 1
		 *   }
		 *
		 *   if (action.type == 'add') {
		 *     Object.assign({}, state, {
		 *       count: ++state.count
		 *     })
		 *   }
		 *
		 *   return state;
		 * });
		 *
		 * Your new state will look like:
		 * BatchGeoStore.getState() // => { MyReducer: {count: 1} }
		 *
		 * @example
		 * BatchGeoStore.addReducer('MyReducer', {count: 1}, {
		 *   add: function (state, action) {
		 *     // Remember `state` is a clone. Mutate however you want!
		 *     return Object.assign({}, state, {
		 *       count: ++state.count
		 *     });
		 *   }
		 * });
		 *
		 * Your new state will look identical to the first example.
		 *
		 * More info reducers: http://redux.js.org/docs/basics/Reducers.html
		 */
		addReducer: function (name, defaultStateOrNewReducer, actionHandlingHash) {
			// If defaultStateOrNewReducer is a function then use it as is. This
			// was the original way as well since BatchGeoStore "1.0".
			if (typeof defaultStateOrNewReducer === 'function') {
				this._reducers[name] = defaultStateOrNewReducer;
			}
			// If defaultStateOrNewReducer is an object we need to generate the
			// reducer function before combining it.
			else if (typeof defaultStateOrNewReducer === 'object') {
				this._reducers[name] = function (state, action) {
					state = state || defaultStateOrNewReducer;

					// If there is a handler function provided call that function
					// and expect to get back an object that we can merge in with
					// the state.
					if (actionHandlingHash[action.type]) {
						return actionHandlingHash[action.type](_.cloneDeep(state), action);
					}
					// If a handler does not exist do nothing. Just return the
					// old state
					else {
						return state;
					}
				}
			}

			// Combine all the reducers and then replace the global reducer
			this.getStore().replaceReducer(this.combineReducers(this._reducers));
		},

		/**
		 * A helper that gets the BGS store and calls getState() on it
		 *
		 * @return {object} The store's state object
		 */
		getState: function () {
			return this.getStore().getState.apply(this, arguments);
		},

		/**
		 * A helper that calls dispatch on the BGS store. It supports a single
		 * action or an array of actions.
		 *
		 * @example
		 * BatchGeoStore.dispatch({ type: 'FOO_BAR' })
		 *
		 * BatchGeoStore.dispatch([
		 *   { type: 'FOO_BAR' },
		 *   { type: 'HELLO_WORLD' }
		 * ])
		 *
		 * @return {undefined}
		 */
		dispatch: function (actions) {
			// TODO: _.castArray
			if (!(actions instanceof Array)) {
				actions = [actions];
			}

			actions.forEach(function (action) {
				this.getStore().dispatch(action);
			}, this)
		},
		/**
		 * addListener is a higher level Redux.subscribe() method that will only
		 * fire the `onChange` callback when a state change happens with the state
		 * you watch with the first argument, `select`. You then check if the state
		 * is different everytime a dispatch happens in the `stateChecker` argument.
		 * The example illustrates it much clearer.
		 *
		 * @param {function|string} select A function or string returning the state
		 * to watch. You can use a string if you want to get the entire Module
		 * instead of a property.
		 * @param {function|string} stateChecker The method to compare new and
		 * previous states. If a function, return "true" if the state is different
		 * to trigger the onChange function. If a string, it will check the new
		 * state and previous state's property with that name's value. For example, if
		 * the state had an "open" property and you passed "open" for stateChecker
		 * it'd check if newState.open !== prevState.open.
		 * @param {function} onChange A callback to fire if the state is new
		 *
		 * @example
		 * BatchGeoStore.addListener(
		 *   function (state) {
		 *     // We only care about our "selectedItems" property in this instance.
		 *     return state.MyModule.selectedItems;
		 *   },
		 *   function (nextSelectedItems, previousSelectedItems) {
		 *     // If they do not equal then the state is new!
		 *     return nextSelectedItems !== previousSelectedItems;
		 *   },
		 *   function (selectedItems) {
		 *     // If it's a new state because the previous function returns truthy
		 *     // then this fires. The watched state data is returned as the first
		 *     // arg as a convenience.
		 *     updateView(selectedItems);
		 *   }
		 * )
		 *
		 * @example
		 * // Same as above using the string selector format
		 * BatchGeoStore.addListener('MyModule',
		 *   function (nextState, previousState) {
		 *     return nextState.selectedItems !== previousState.selectedItems;
		 *   },
		 *   function (nextState) {
		 *     updateView(nextState.selectedItems);
		 *   }
		 * )
		 *
		 * @example
		 * // Same as above using a string for the stateChecker
		 * BatchGeoStore.addListener('MyModule', 'selectedItems', function (nextState) {
		 *     updateView(nextState.selectedItems);
		 *   }
		 * )
		 *
		 * @return {function} The Redux unsubscribe function
		 */
		addListener: function(select, stateChecker, onChange) {
			if (typeof select === 'string') {
				// Save the string that was given to us as the first param
				// before we override it with a function or else
				// `state[select]` would reference itself.
				var selectString = select;
				select = function (state) {
					return state[selectString];
				}
			}

			// If the stateChecker is passed as a string we assume they want to
			// compare the value of a key in the state. For example, if the state had
			// an "open" property and you passed "open" for stateChecker it'd check
			// if newState.open !== prevState.open.
			if (typeof stateChecker === 'string') {
				var stateCheckerString = stateChecker;
				stateChecker = function (newState, prevState) {
					return !_.isEqual(newState[stateCheckerString], prevState[stateCheckerString]);
				}
			}

			var store = this.getStore();
			var prevState, newState;

			prevState = select.call(store, store.getState());
			return store.subscribe(function () {
				newState = select.call(store, store.getState());
				if (stateChecker.call(store, newState, prevState)) {
					//Saving the previous state so we can pass it as a ref in onChange()
					var tempOldState = prevState;
					//we set the prev state before calling onChange() to handle
					//chained actions that depend on each other. onChange()
					//will have the latest state at all times
					prevState = newState;
					onChange.call(store, newState, tempOldState);
				}
			});
		},

		/**
		 * A helper function to unsubscribe from an addListener. Although
		 * addListener returns the Redux subscribe which you can call to unsubscribe
		 * it's not obvious and we provide this to make it more obvious how.
		 * @param  {function} handler The unsubscribe function you got from addListener
		 *
		 * @return {undefined}
		 */
		removeListener: function (handler) {
			handler();
		},

		/**
		 * Since we're not letting you, the consumer, create and manage the store
		 * object we provide a method to get the store.
		 *
		 * @returns {Redux}
		 */
		getStore: function () {
			return BatchGeoStore._store;
		},

		/**
		 * Resets the current store back to the original states but keeps the
		 * reducers around. In one page apps, this is useful when switching between
		 * maps when you want to get a fresh state as if it was a full page reload.
		 */
		resetStore: function () {
			this._createStore();
			return this;
		}
	});

	BatchGeoStore._createStore();

	window.BatchGeoStore = BatchGeoStore;
})();
