import './lib/mlpushmenu'

(function (window) {
	if (typeof customAlert === undefined) {
		customAlert = function(msg) { alert(msg); }; // safe loader in case we are before colorbox load
	}

	if (window.BatchGeoLoginEvents) return;

	// Initialize the signin, login, and other elements
	$(function () {
		var $signin = $('#signin');
		var $menu = $('#mp-menu');
		var $mobileMenu = $('#menu-trigger');
		const $error = $('#sidebar_login_box .error');

		if($signin.length) {
			new mlPushMenu($menu[0], $signin[0], {levelSpacing: 0});
		}
		if ($mobileMenu.length) {
			new mlPushMenu($menu[0], $mobileMenu[0], {levelSpacing: 0});
		}

		$('#sidebar_login_box form').on('submit', function () {
			var emailInputValue = $("#sidebar_login_email").val();
			BatchGeoSession.login(emailInputValue, $("#sidebar_login_password").val(), (xhr, status) => {
				$error.empty();
				var jsonResponse = xhr.responseJSON;
				if (status == 'error') {
					if(!jsonResponse.error){
						// no special orders from API, default response
						$error.text('The user name or password you entered was incorrect, please try again.')
					} else {
						//special order from API, surface message and redirect user
						BatchGeo.blockAlert(jsonResponse.error.message, function() {
							window.location = '/signup';
						});
						return;
					}
				}
				else {
					// location.reload takes a cache busting param. True == cache bust
					location.reload(true);
				}
			});
			return false;
		});

		$("#signin").click(function(e) {
			e.stopPropagation();
			e.preventDefault();
		});

		$("#login_forgot").click(function(e) {
			var email = $("#desktop_login_email").val();
			var $forgotPasswordLink = $("#login_forgot");

			var oldtext = $forgotPasswordLink.html();
			$forgotPasswordLink.html('Please wait...');

			BatchGeoSession.forgotPassword(email, function () {
				$forgotPasswordLink.html(oldtext);
			});

			e.stopPropagation();
		});

		$("#sidebar_login_forgot").click(function(e) {
			var email = $("#sidebar_login_email").val();
			var $forgotPasswordLink = $("#sidebar_login_forgot");

			var oldtext = $forgotPasswordLink.html();
			$forgotPasswordLink.html('Please wait...');

			BatchGeoSession.forgotPassword(email, function () {
				$forgotPasswordLink.html(oldtext);
			});

			e.stopPropagation();
		});

		$("#login_signout, #sidebar_login_signout").click(function(e) {
			BatchGeoSession.logout();
		});

		$("#login_email, #login_password, #sidebar_login_email, #sidebar_login_password").click(function(e) {
			e.stopPropagation();
		});
	})

	$(document).click(function() {
		$("#signin").toggleClass("active", false);
	});
	window.BatchGeoLoginEvents = 1;
	BatchGeo.timer('login events added');
})(window)
