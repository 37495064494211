// TODO(tom) we dont' use papaparse anywhere, re-add it to npm if we choose to use it in future
// import * as Papa from 'papaparse';

/**
 * A collection of global app helper functions such as creating session IDs and
 * flipping features on and off.
 *
 * @type {Object}
 */

(function (window) {

// private utilities only used within this object:
var _URLDecode = function(encoded) {
	var HEXCHARS = "0123456789ABCDEFabcdef";
	var plaintext = "";
	var i = 0;
	while (i < encoded.length) {
		var ch = encoded.charAt(i);
		if (ch == "+") {
			plaintext += " ";
			i++;
		} else if (ch == "%") {
			if (i < (encoded.length-2) && HEXCHARS.indexOf(encoded.charAt(i+1)) != -1 && HEXCHARS.indexOf(encoded.charAt(i+2)) != -1 ) {
				plaintext += unescape( encoded.substr(i,3) );
				i += 3;
			} else {
				plaintext += "%[ERROR]";
				i++;
			}
		} else {
			plaintext += ch;
			i++;
		}
	} // while
	return plaintext;
};

var _addMetric = function(category, filter, value, value2) {
	$.ajax({
		url: '/api/addMetric/',
		type: 'POST',
		data: {
			category: category,
			filter: filter,
			value: value,
			value2: value2
		},
		dataType: 'text',
		cache: false,
		error: function() { BatchGeo.timer('addMetric error'); }
	}); // submit form, response will be in script
};

window.BatchGeo = {
	// global getters
	getDevicePixelRatio: function () {
		return window.devicePixelRatio || 1;
	},

	isRetina: function () {
		return this.getDevicePixelRatio() > 1;
	},

	getRetinaSuffix: function () {
		return this.isRetina() ? '@2x' : '';
	},

	/**
	 * Checks if rendering in an iframe. 
	 * 
	 * Uses `window.frameElement` to determine if in iframe on same domain.
	 * 
	 * Uses `window !== window.top` to determine if in iframe on different domain.
	 * 
	 * Try/Catch handles error thrown on firefox if attempting to access top or parent
	 * when on different domains.
	 * 
	 * @returns {boolean} true if in iframe, false if not
	 */
	isInIframe() {
		try {
			return Boolean(window.frameElement || window !== window.top);
		} catch (e) {
			return true;
		}
	},

	msie: /(msie)/gi.test( navigator.userAgent ),

	timerStart: new Date().getTime(),

	timer: function(label) {
		if (BatchGeoStore.getState().App.isDev) {
			if (!this.lastTime) this.lastTime = this.timerStart;
			const curTime = new Date().getTime();
			console.log((curTime - this.timerStart) + "ms / " + (curTime - this.lastTime) + "ms " + label);
			this.lastTime = curTime;
		}
	},

	addTimedMetric: function(category, filter, value2) {
		// get time since last timer stamp from global and upload
		return _addMetric(category, filter, ((new Date()).getTime() - this.lastTime), value2);
	},

	/**
	 * Caches the map view in BatchGeoPersistence to be retrieved later. If a view
	 * is passed in it uses that for caching. If not it will create a generic map
	 * cache as long as a map exists and has bounds.
	 */
	setMapViewCache: function (view) {
		var mapViewCachePersist = new BatchGeoPersistence({key: 'MapViewCache'});
		if (!view) {
			var map = window.batchGeoGoogleMapsManager.getMap();
			if (!map || !map.getBounds()) return;

			view = {
				zoom: map.getZoom(),
				lat: map.getCenter().lat(),
				lng: map.getCenter().lng(),
				type: map.getMapTypeId(),
				legendFilters: window.batchGeoGoogleMapsMarker.MarkerLegend.getFilters(),
				openRow: BatchGeoStore.getState().MapsMarkersManager.openStatus && window.batchGeoGoogleMapsMarker.lastMarker ? window.batchGeoGoogleMapsMarker.lastMarker.r : -1,
				column: window.batchGeoGoogleMapsMarker.MarkerLegend.getColumn(),
				heatmap: BatchGeoStore.getState().HeatMap.enabled,
				cluster: BatchGeoStore.getState().Cluster.enabled
			};
		}

		mapViewCachePersist.set(BatchGeoStore.getState().App.mapId, view);

		return view;
	},

	/**
	 * Fetches the cache for the current map view. If there's no views cached at
	 * all it returns an empty object as a helper so you can reliably do:
	 * `getMapViewCache().somePropertyLikeZoom` without worrying if it'll be
	 *  undefined or falsy.
	 *
	 *  @return {object} The map view and if no map view exists an empty object
	 */
	getMapViewCache: function () {
		var mapViewCachePersist = new BatchGeoPersistence({key: 'MapViewCache'});
		return mapViewCachePersist.fetch(BatchGeoStore.getState().App.mapId) || {};
	},

	/**
	 * Clears the current map's view cache
	 *
	 *  @return {undefined}
	 */
	clearMapViewCache: function () {
		var mapViewCachePersist = new BatchGeoPersistence({key: 'MapViewCache'});
		mapViewCachePersist.destroy([BatchGeoStore.getState().App.mapId])
	},

	/**
	 * Generates a unique session ID based on the GUID algo and stores it in
	 * BatchGeoPersistence. If a session ID already exists it returns it and does
	 * not create a new one. The IDs are completely random and anonymous.
	 *
	 * @return {string} A GUID string
	 */
	getSessionId: function () {
		// If the clientSessionId already exists save it
		var sessionPersist = new BatchGeoPersistence({key: 'ClientSession'});

		// So we can keep tracking users from before BatchGeoPersistence if the old
		// clientSessionId exists import it into our new persistence layer
		
		try {
			if (localStorage.clientSessionId) sessionPersist.set({id: localStorage.clientSessionId})
		} catch (e) {
			console.error(e);
		};

		var sessionId = sessionPersist.fetch('id');
		if (sessionId) return sessionId;

		var gen = function (count) {
			var out = "";
			for (var i=0; i<count; i++) {
				out += (((1+Math.random())*0x10000)|0).toString(16).substring(1);
			}
			return out;
		};

		var guid = [gen(2), gen(1), gen(1), gen(1), gen(3)].join("-");

		sessionPersist.set({id: guid});

		return guid;
	},

	/**
	 * Given a title (first param) and a URL encoded address (second param) this function
	 * will open a modal with a streetview image at ~90% of the screen width and height.
	 *
	 * @param {string} title The title you want displayed under the image in the modal
	 * @param {string} urlAddress A URL encoded address for the streetview image look up
	 */
	openStreetViewImage: function(title, urlAddress) {
		var streetViewUrl = '/api/streetview/?size=2048x2048&location=' + urlAddress;
		var windowHeight = window.innerHeight ? window.innerHeight : $(window).height();
		var windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
		var windowSize = parseInt((windowHeight > windowWidth ? windowWidth : windowHeight) * 0.9);

		// IMPORTANT: for unknown reasons, if you replace this with pure HTML
		// like `html: <img src="abc.jpg">` it will not render on the home page.
		// See: #1741
		$.colorbox({
			title: title,
			photo: true,
			width: windowSize,
			height: windowSize,
			href: streetViewUrl + '&sensor=false'
		});

		// Prevent event propagation so that other events attached above the element with
		// this handler don't interfere. For example, in the data view if this was clicked
		// without this the page would scroll up top to the map again because clicking a
		// data view row opens a map marker info window.
		window.event.stopPropagation();
	},

	/**
	 * Synchronously checks if the provided email belongs to the logged in users subscription.
	 * Calls the provided callback in the success method. 
	 * 
	 * @param {string} email 
	 * @param {*} successCb takes '0' for invalid or '1' for valid as input
	 * 
	 * @example
	 * 	BatchGeo.emailBelongsToSubscriptionSync('example@example.com', function(intAsStr) {
	 * 		// handle response and execute custom code
	 * 	})
	 */
	emailBelongsToSubscriptionSync: function(email, successCb) {
		$.ajax({
			url: '/api/checkAllowedEmail/?email=' + encodeURIComponent(email),
			type: 'GET',
			async: false,
			cache: false,
			timeout: 5000,
			error: function () {
				return true;
			},
			success: successCb,
		});
	},

	/**
	 * This method does not work consistently across all browsers/context.
	 * A string like 'SCC Division 1' will be parsed and pass isNaN on Chrome browsers.
	 * The same string will not pass isNaN on some Safari browsers.
	 */
	isDate: function(value) {
		return !isNaN(Date.parse(value));
	},

	/**
	 * Attempts to detect a GPS type coordinate by looking for degree symbols,
	 * digits, and a N, W, S, or E letter.
	 *
	 * @returns {boolean} True if it looks like a GPS coordinate false if not.
	 */
	isDmsCoordinate: function(coordinate) {
		return /\d+\.?° \d+\.?['"]'? \d+(\.\d+)?['"]'? [NWSE]/.test(coordinate);
	},

	/**
	 * Given the full DMS (degrees, minutes, seconds, and direction) will do the
	 * math required to convert it to a decimal. If you have a string and not
	 * the full DMS broken out then you should use #parseDms.
	 *
	 * @see {@link BatchGeo.parseDms}
	 *
	 * @param {string|number} degrees The degrees of the coordinate
	 * @param {string|number} minutes The minutes of the coordinate
	 * @param {string|number} seconds The seconds of the coordinate
	 * @param {string} direction The direction of the coordinate. This should be
	 * N, S, W, or E.
	 *
	 * @returns {number} A decimal of the coordinate
	 */
	convertDmsToDecimal: function (degrees, minutes, seconds, direction) {
		// 60*60 is left to make it clear that we're going from minutes to seconds
		var decimal = parseFloat(degrees) + parseFloat(minutes)/60 + parseFloat(seconds)/(60*60);

		// If it's a S or W we need to convert to a negative number
		if (direction == "S" || direction == "W") {
			decimal = -decimal;
		}

		return decimal;
	},

	/**
	 * Takes a GPS coordinate for one direction and returns a decimal coordinate
	 * instead of DMS. This is useful if you have a string and need to parse it
	 * out. If you have the degrees, minutes, seconds, and direction already
	 * then you can use #convertDmsToDecimal instead.
	 *
	 * @see {@link BatchGeo.convertDmsToDecimal}
	 *
	 * @example
	 * var decimalValue = BatchGeo.parseDms('35° 28' 54.9048'' N')
	 * console.log(decimalValue) // 35.481918
	 *
	 * @param {string} dmsString The GPS coordinate to turn into a decimal
	 *
	 * @returns {number} A decimal of the coordinate
	 */
	parseDms: function (dmsString) {
		var parts = dmsString.split(/[^\d\w\.]+/);
		return this.convertDmsToDecimal(
			parseFloat(parts[0]),
			parseFloat(parts[1]),
			parseFloat(parts[2]),
			parts[3]
		);
	},

	getDimensions: function(content, htmlClass) {
		var sensor = $('<div>').html(content).addClass(htmlClass);
		$(sensor).appendTo("body").css({"position" : "absolute", "left" : "-999px"});
		var width = $(sensor).outerWidth();
		var height = $(sensor).outerHeight();
		$(sensor).remove();
		return [width, height];
	},

	addCommas: function(nStr) {
		nStr += '';
		var x = nStr.split('.');
		var x1 = x[0];
		var x2 = x.length > 1 ? '.' + x[1] : '';
		var rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1)) {
			x1 = x1.replace(rgx, '$1' + ',' + '$2');
		}
		return x1 + x2;
	},

	/**
	 * A little helper that, given a form element (in a selector string, jQuery,
	 * or HTMLElement), it will return an object that represents the form and
	 * each value of the form fields. Currently supports input, textarea, and
	 * buttons.
	 *
	 * NOTE: It requires `name` attributes on form fields! If there is no `name`
	 * attribute it will simply ignore the field.
	 *
	 * @example
	 * If this was the HTML
	 * <form>
	 *   <input name="foo">
	 *   <button name="bar">
	 * </form>
	 *
	 * Then the user typed in "hello world" into the input you would get:
	 *
	 * {
	 *   foo: "hello world",
	 *   bar: ""
	 * }
	 *
	 * @param {*} formElement A string selector, jQuery object, HTMLElement or
	 * any form of element that is valid within a jQuery selector like
	 * `$('.foo')`, `$($('.foo'))`, or `$(document.body)`.
	 *
	 * @return {object} A key=>value object with a mapping of
	 * name attribute=>form field value
	 */
	formValuesToObject: function (formElement) {
		var formValueObject = {};
		$(formElement).find('input, textarea, button').each(function (index, element) {
			var key = $(element).attr('name');
			if (key) {
				formValueObject[key] = $(element).val();
			}
		})
		return formValueObject;
	},

	/**
	 * Generates a volatile UID (Unique Identifier).
	 * 
	 * This function is suitable for generating less than 100K temporary/disposable UIDs that are unique to their context, resource, instance, and user.
	 * 
	 * It's commonly used for generating UIDs for map clusters, where the clusters and UIDs are generated at the start of each user
	 * session to limit the opportunity for collision and enable easy resource reference within the lifetime of the instance.
	 * 
	 * @warning This method is not suitable for persistent data/IDs.
	 * @param {Object} [seen] An optional object that allows the method caller to provide a set of previously seen UIDs to prevent collisions. If provided, the function will recursively generate a new UID until it's unique within the context of the provided `seen` object. Defaults to `undefined`.
	 * @returns {String} A unique identifier (UID) string generated based on the current timestamp and a random string.
	 * @example
	 * // Generating a volatile UID without providing a `seen` object
	 * const uid = volatileUID(); // Example: '14lkj29x1n'
	 * 
	 * // Generating a volatile UID with a `seen` object to prevent collisions
	 * const seen = { '14lkj29x1n': true }; // Assume this UID has been seen before
	 * const uid = volatileUID(seen); // Example: '5oq1mp4z9e' (unique because it's not in the `seen` object)
	 */
	volatileUID: function (seen = undefined){
		if (seen && typeof seen === 'object') {
			const newId = Date.now().toString(36) + Math.random().toString(36).substring(2);
			if (seen[newId]) {
				// Retry if the new UID is already in the `seen` object
				return BatchGeo.volatileUID(seen);
			}
			return newId;
		}
		// Generate a new UID without considering the `seen` object
		return Date.now().toString(36) + Math.random().toString(36).substring(2);
	},

	/**
	 * converts large numbers to 100K, 1M type of abbreviated numbers
	 */
	tinyNum: function(n, fixed = 1) {
		if (!isFinite(n) || isNaN(n)) return 0;
		// strip unnecessary decimals
		n = (n == parseInt(n) ? parseInt(n) : n);

		if (n < 9999) return n;
		// just add commas
		if (n < 99999) return this.addCommas(n);
		// reduce to thousands
		if (n < 1000000) return parseFloat((n/1000).toFixed(fixed)) + 'K';
		// reduce to millions
		if (n < 1000000000) return parseFloat((n/1000000).toFixed(fixed)) + 'M'
		// else reduce to billions
		return parseFloat((n/1000000000).toFixed(fixed)) + 'B';
	},

	getSignificantRoundingValue: function(n1, n2, x=1) {
		while(this.tinyNum(n1, x) === this.tinyNum(n2, x)){
			x++;
		}
		return x;
	},

	radians: function(degrees) {
		return degrees * 0.0174532925;
	},

	stripQuotes: function(val) {
		if (val.length >= 3) {
			if (this.leftSubStr(val, 1) == '"') val = this.rightSubStr(val, val.length-1);
			if (this.rightSubStr(val, 1) == '"') val = this.leftSubStr(val, val.length-1);
		}
		return val;
	},

	distance: function(lat1, long1, lat2, long2) {
		var R = per.dist_sel ? 6378.137 : 3963.19059;
		var dist = R * Math.acos(
			Math.cos(this.radians(90-lat1)) *
			Math.cos(this.radians(90-lat2)) +
			Math.sin(this.radians(90-lat1)) *
			Math.sin(this.radians(90-lat2)) *
			Math.cos(this.radians(long1-long2))
		);
		return Math.abs(dist);
	},

	distance2: function(lat1, long1, lat2, long2) {
		// calculates distance but keeps in lat/long
		var dist = Math.sqrt(Math.pow((lat1 - lat2),2) + Math.pow((long1 - long2),2));
		return Math.abs(dist);
	},

	/**
	 * Gets the radius of the equator in the given scale provided. These numbers
	 * are based on NASA's number in km from:
	 * https://nssdc.gsfc.nasa.gov/planetary/factsheet/earthfact.html
	 *
	 * @param {string} [scale] The scale you want to get the radius for.
	 * Supported scales or units are: `mi`, `ft`, `km`, and `m`.
	 *
	 * @returns {number} It will return the radius for the given scale. As a
	 * fallback for an invalid or falsy scale it will return the km radius.
	 */
	getEquatorialRadius: function (scale) {
		var km = 6378.137;
		var miles = 3963.19059;
		switch (['mi', 'ft', 'km', 'm'].indexOf(scale)) {
			case 0: return miles;
			case 1: return 5280 * miles; // 5280 = ft in a mile
			case 2: return km;
			case 3: return km * 1000;
			default: return km;
		}
	},

	validateEmail: function(elementValue) {
		var emailPattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return emailPattern.test(elementValue);
	},

	findEmail: function(string) {
		var result = string.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
		return result;
	},

	iframeDetect: function() {
		var iframe = false;
		try {
			if (window!=window.top) iframe = true;
		} catch(e) {
			iframe = true;
		}
		return iframe;
	},

	httpsDetect: function () {
		return document.location.protocol === "https:";
	},

	/**
	 * Takes a count and then the string and creates a string with an S on the
	 * end if it's not a number 1.
	 *
	 * @example
	 * pluralize(1, "location")
	 * > 1 location
	 *
	 * pluralize(2, "unread email")
	 * > 2 unread emails
	 *
	 * @param {number|string} count Count to display and check if an S will be
	 * added. It takes a number OR a string representation of a number.
	 * @param {string} text The text to append after count and append an S to
	 * @return {string} Returns a final string in the format of `count + text`
	 */
	pluralize: function (count, text) {
		return count + ' ' + text + (count != 1 ? 's' : '');
	},

	/**
	 * Returns `false` if not IE and returns a number if IE.
	 *
	 * @example
	 * if (BatchGeo.isIE()){ // if *any* IE version }
	 *
	 * if (BatchGeo.isIE() && BatchGeo.isIE() > 9){ // if IE and IE version is 10 or more }
	 *
	 * if (!BatchGeo.isIE()){ // if not IE }
	 *
	 * @return {boolean|number}
	 */
	isIE: function() {
		var ua = window.navigator.userAgent;

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		var edge = ua.indexOf('Edge/');
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return false;
	},

	/**
	 * goToHash will update the URL hash with the given string. It prepends all
	 * hashes with `!/`. For example, passing `foo` will results in a URL like
	 * `/#!/foo`. If you pass a falsy value it will remove this.
	 *
	 * In addition this will automatically scroll the page to the top.
	 *
	 * @param {string|undefined|null} [hash] The string you want to display in the
	 * URL after !/. If falsy it will wipe out the hash completely.
	 *
	 * @returns {undefined}
	 */
	goToHash: function (hash) {
		if (hash) {
			window.location.hash = '!/' + hash;
		}
		// This will wipe out the hash if no hash is given
		else {
			history.pushState("", document.title, window.location.pathname + window.location.search);
		}
		window.scrollTo(0,0);
	},

	/**
	 * A method that simply calls window.location. Why use this and not just
	 * call window.location? This way you can override in unit tests. This does
	 * not have tests on it itself because it can't have tests. You will get a
	 * "Some of your tests did a full page reload!" error.
	 *
	 * @param {string} location The location to redirect to
	 */
	redirect: function (location) {
		window.location = location;
	},

	/**
	 * Returns the IE version if <IE11. If it's not IE or IE11+ it will return
	 * false.
	 *
	 * Side Note: Intellij will incorrectly say this function is unused. It is
	 * used in map/index.php.
	 *
	 * @return {Boolean|Number}
	 */
	isOldIE: function () {
		var myNav = navigator.userAgent.toLowerCase();
		return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
	},

	/* begin custom alerts, requires colorbox.js */
	customAlert: function (message) {
		this.customDialog(message);
	},

	blockAlert: function (message, confirmFn) { // wait till OK gets pressed then run confirmFn code
		return this.customDialog(message, { confirmFn: confirmFn, dismissFn: null, showDismissBtn: false });
	},

	customConfirm: function (message, confirmFn, dismissFn) {
		return this.customDialog(message, { confirmFn: confirmFn, dismissFn: dismissFn, showDismissBtn: true });
	},

	customDialog: function (messageText, optionOverrides, headerText) {
		var strings = BatchGeoStrings.getStringsForComponent('Global');
		if ($.hasOwnProperty("colorbox")) {
			var options = $.extend({}, {
				showConfirmBtn: true,
				showDismissBtn: false,
				confirmFn: function(){},
				dismissFn: function(){},
				confirmText: strings.get('OK'),
				dismissText: strings.get('CANCEL'),
				heightOffset: '37%',
				width: '100%',
				maxWidth: 450
			}, optionOverrides);

			// If we pass a string replace new lines with breaks so it looks the
			// same visually. Otherwise, messageText could actually be a DOM
			// object, undefined, null, etc so .replace would error out.
			if (typeof messageText === 'string') {
				messageText = messageText.replace(/\n/g,"<br />");
			}

			if (headerText){
				var heading = $('<h3 class="dialogue-header">').html(headerText);
			}

			var message = $('<p class="dialog-message">').html(messageText);
			var buttons = $('<p class="dialog-buttons">');

			var btnA = $('<button class="button gold confirm-btn" tabindex="-1"></button>').text(options.confirmText);
			var btnB = $('<button class="button blue dismiss-btn" tabindex="-1"></button>').text(options.dismissText);

			btnA.click(function(){ options.confirmFn(); $.colorbox.close(); });
			btnB.click(function(){ options.dismissFn(); $.colorbox.close(); });

			if (options.showDismissBtn) buttons.append(btnB);
			if (options.showConfirmBtn) buttons.append(btnA);

			$.colorbox({
				html: $('<div>').append([heading, message, buttons]),
				width: options.width,
				maxWidth: options.maxWidth,
				top: options.heightOffset,
				fixed: true,
				closeButton: false,
				escKey: false,
				overlayClose: false,
				transition: 'none',
				fadeOut: 0,
				className: 'custom-dialog'
			});

			btnA.focus();
		}
		else {
			// place fallback here so we don't have to have it in other files
			alert(messageText);
		}
	},
	/* end custom alerts */

	/**
	 * A helper to always return a trimmed string. If you pass something that is not
	 * a string it will return an empty string. If you pass a string, and your
	 * browser supports String.prototype.trim() it will trim it and return the
	 * trimmed string.
	 *
	 * @param  {anything} string Anything that you want to convert into a string
	 * @return {string}
	 */
	ifstr: function(string) {
		return string ? (string.trim ? string.trim() : string) : '';
	},

	/**
	 * Converts a string of a number into a float. The function is meant for US
	 * dollar amounts. For example, if you pass "$1,200.12" you get back 1200.12. If
	 * you pass in an empty string you wil get 0 back.
	 *
	 * NOTE: If you pass in a string that can't be parsed it will return NaN
	 *
	 * @param  {string|number} n The number to parse into a float
	 * @return {number} Returns a float version of the string/number you passed in
	 */
	val: function(n) {
		if (!BatchGeo.isTrueNumber(n)) {
			// Make sure n is a string
			n = n.toString();

			// Remove all whitespace
			n = n.trim();

			// If n is an empty string after removing spaces return 0
			if (n === '') return 0;

			// Remove everything that isn't a decimal, negative symbold or digit
			n = n.replace(/[^\d.-]/g, '')

			n && n.length ? n[0] : 0;
		}

		return parseFloat(n)
	},

	leftSubStr: function(str, n){
		if (n <= 0) return "";
		if (n > String(str).length) return str;
		return String(str).substring(0,n);
	},

	rightSubStr: function(str, n) {
		if (n <= 0) return "";
		if (n > String(str).length) return str;

		var iLen = String(str).length;
		return String(str).substring(iLen, iLen - n);
	},

	/**
	 * Takes a value (n) and checks if it's a real number but a string type. For
	 * example it'll return true for "1" or "1.1" but not $1. If you want to
	 * verify numbers that are *probably* numbers to humans use the
	 * BatchGeo.isNumber method which tries to guess is a value is a number.
	 *
	 * Important note: In JavaScript NaN is a number. This reflects that so NaN
	 * will be a number.
	 *
	 * @see {@link BatchGeo.isNumber}
	 *
	 * @param {*} n The value to check if it's a number
	 * @returns {boolean} Returns true if it's a number
	 */
	isTrueNumber: function (n) {
		if (typeof n === 'number') return true;
		return !isNaN(parseFloat(n)) && isFinite(n);
	},

	/**
	 * Takes a value (n) and tries to validate if a human would consider it a
	 * number. For example 10%, $1.10, etc are not actually numbers but a human
	 * would consider them to be. This function takes a guess at that and
	 * returns a boolean. If you're looking for true number validation where $1
	 * would actually fail, use BatchGeo.isTrueNumber.
	 *
	 * @see {@link BatchGeo.isTrueNumber}
	 *
	 * @param {*} n The value to check if it's probably a number
	 * @returns {boolean} Returns true if it's probably a number
	 */
	isNumber: function(n) {
		// If it's already a true number return true early
		if (BatchGeo.isTrueNumber(n)) return true;

		// Remove spaces
		n = n.replace(/\s/g, '');

		// Remove commas
		n = n.replace(/,/g, '');

		// If the last character is a string and remaining is number, let's remove it. 
		// This could be a % symbol for example.
		if (typeof n[0] == 'string' && BatchGeo.isTrueNumber(this.leftSubStr(n, n.length - 1))) {
			n = this.leftSubStr(n, n.length - 1);
		}

		// If the first character is a string and remaining is number, let's remove it too.
		// This could something like a negative, dollar, etc symbol
		if (typeof n[0] == 'string' && BatchGeo.isTrueNumber(this.rightSubStr(n, n.length - 1))) {
			n = this.rightSubStr(n, n.length - 1);
		}

		// False if it looks like a date
		if (n.match && n.match(/-|\/|\\|:/g)) return false;
		
		// False if it's an empty string
		if (!n.length) return false;
		
		// After the clean up, is it a number yet?
		if (!BatchGeo.isTrueNumber(n)) return false;

		// If it's looking like a number convert the string into an actual
		// number by getting it's "value" with .val()
		n = this.val(n);

		// After cleaning the number, is it now a true number?
		return BatchGeo.isTrueNumber(n);
	},

	/**
	 * Simple helper to validate a value's type against an allowedTypes list/array.
	 * 
	 * @param {*} value The value to validate
	 * @param {Array} allowedTypes Can contain "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"
	 * @returns {Boolean}
	 */
	isValidType: function(value, allowedTypes) {
		const typeofValue = typeof value;
		return allowedTypes.some(allowedType => allowedType === typeofValue);
	},

	/**
	 * A helper to validate a given lat and lon. If the lat and lon is not valid it
	 * will return false. If they're both valid it will return true. It will also
	 * attempt to convert lat/lon strings to actual floats.
	 *
	 * @param  {string|number} lat The latitude to validate
	 * @param  {string|number} lon The longitude to validate
	 * @return {undefined}
	 */
	goodLatLon: function(lat, lon) {
		const allowedTypes = ["string", "number"];
		if (!this.isValidType(lat, allowedTypes) || !this.isValidType(lon, allowedTypes)) {
			return false;
		} 
		lat = parseFloat(this.val(lat));
		lon = parseFloat(this.val(lon));
		if (Math.abs(lat) < 0.00001 || Math.abs(lon) < 0.00001) return false;
		return ((lat <= 90 && lat >= -90) && (lon <= 180 && lon >= -180));
	},

	/**
	 * Returns true if the given param contains a $
	 * @param {*} n A value to check for a $
	 * @return {boolean}
	 */
	isDollarAmount: function(n) {
		return n.toString().indexOf('$') !== -1;
	},

	/**
	 * Returns true if the given param contains a %
	 * @param {*} n A value to check for a %
	 * @return {boolean}
	 */
	isPercentage: function(n) {
		return n.toString().indexOf('%') !== -1;
	},

	getQueryVariable: function(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i=0;i<vars.length;i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) {
				return _URLDecode(pair[1]);
			}
		}
		return "";
	},

	isTime: function(time) {
		return time.match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/);
	},

	parseTime: function(timeStr, dt) {
		if (!dt) {
			dt = new Date();
		}
		var time = timeStr.match(/(\d+)(?::(\d\d))?\s*(p?)/i);
		if ( !time ) {
			return NaN;
		}
		var hours = parseInt( time[1], 10 );
		if ( hours === 12 && !time[3] ) {
			hours = 0;
		} else {
			hours += (hours < 12 && time[3]) ? 12 : 0;
		}
		dt.setHours(hours);
		dt.setMinutes(parseInt(time[2], 10) || 0);
		dt.setSeconds(0, 0);
		return dt;
	},

	/**
	* Strips HTML from the given string and optionally let's you replace it with
	* something else.
	*
	* @param {*} str The string to search HTML tags for
	* @param {string|number} [replacement=''] What to replace the HTML with
	* @return {string}
	*/
	stripHTML: function(str, replacement) {
		// If null or undefined (or another object without toString) this will
		// error out. Always fallback to a string type
		try { str = str.toString(); }
		catch (e) { str = '' }

		replacement = replacement || '';
		return str.replace(/<(?:.|\n)*?>/gm, replacement);
	},

	rS: function(selectObj) {
		for (var i=0;i<selectObj.options.length;i++)
			if (selectObj.options[i].selected) {
				return i-1;
			}
		return -1;
	},

	/**
	 * Validates a string to make sure that string is a URL
	 * @param {string} str A string to validate as a URL
	 * @returns {boolean} Returns true if the given string is a URL
	 */
	isValidURL: function(str) {
		var a  = $('<a/>', {href: str})[0];
		// Note: this assumes URLs won't be hosted on the same server. For
		// example on batchgeo.com if you made the url batchgeo.com/logo.png it
		// would fail
		return (a.host && a.host != window.location.host);
	},

	getCookie: function(name) {
		var dc = document.cookie;
		var prefix = name + "=";
		var begin = dc.indexOf("; " + prefix);
		if (begin == -1) {
			begin = dc.indexOf(prefix);
			if (begin !== 0) return null;
		} else
			begin += 2;
		var end = document.cookie.indexOf(";", begin);
		if (end == -1)
			end = dc.length;
		return unescape(dc.substring(begin + prefix.length, end));
	},

	/**
	 * Given a card number this function returns the type of card it is.
	 * Supported cards are:
	 * - MasterCard
	 * - Visa
	 * - AmEx
	 * - Discover
	 *
	 * All others will return unknown.
	 *
	 * @param {string|number} num A number (in number or string format) to check
	 * the type of.
	 *
	 * @returns {string} Will return a string of Mastercard, Visa, AmEx, or
	 * Discover if it knows the type or else it will return "UNKNOWN."
	 */
	creditCardTypeFromNumber: function (num) {
		num = num + ''; // make it a string;

		// first, sanitize the number by removing all non-digit characters.
		num = num.replace(/[^\d]/g,'');

		// now test the number against some regexes to figure out the card type.
		if (num.match(/^5[1-5]\d{14}$/)) {
			return 'MasterCard';
		} else if (num.match(/^4\d{15}/) || num.match(/^4\d{12}/)) {
			return 'Visa';
		} else if (num.match(/^3[47]\d{13}/)) {
			return 'AmEx';
		} else if (num.match(/^6011\d{12}/)) {
			return 'Discover';
		}

		return 'UNKNOWN';
	},

	/**
	 *
	 * Function for setting a cookie.
	 *
	 * @param {string} name of the cookie you would like to set
	 * @param {string|number} value for the cookie.
	 * @param {number} [days] long should this cookie stay for?
	 */

	setCookie: function(name, value, days)  {
	
		var expires = "";
		var secure = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = "; expires=" + date.toUTCString();
		}
		if(window.location.protocol == 'https:'){
			secure = '; SameSite=None; Secure'
			document.cookie = name + "=" + (value || "") + secure + expires + "; path=/";
		}else{
			document.cookie = name + "=" + (value || "") + expires + "; path=/";
		}
	},

	/**
	 * parseCsv takes a string for value and parses it into a multidimensional
	 * array like [["row", "1"], ["row", "2"]]
	 *
	 * It currently, under the hood, uses Papaparse. The options param will
	 * override the defaults and merge in new options for Papa#parse.
	 *
	 * @param {string} value A string representation of a CSV (or TSV)
	 * @param {object} [options] if you want to override the default options you
	 * can do so with this. Below are the defaults:
	 * @param {string} [options.delimiter='\t'] The character that represents a new
	 * cell. For example `foo,bar` you would set delimiter to `,`.
	 * @param {boolean} [options.quoteChar=false] The character that represents
	 * an escaped cell value. For example if you know for sure your cells are
	 * separated with " like "foo","bar" then you would set it to ". By default
	 * this is disabled for the majority of usecases for BatchGeo.
	 * @param {boolean} [options.skipEmptyLines=true] If totally empty rows
	 * should be skipped and not parsed. With this disabled you can end up with
	 * return values of like [["foo", "2", "bar"], ["", "", ""]]. With it true
	 * you would only get that first row.
	 *
	 * @returns {array} You will get a multidimensional array back.
	 *
	 * @example
	 * // Default usage
	 * BatchGeo.parseCsv('foo	bar\nhello	world')
	 * // [["foo","bar"],["hello","world"]]
	 *
	 * BatchGeo.parseCsv('foo,bar\nhello,world', {delimiter: ','})
	 * // [["foo","bar"],["hello","world"]]
	 *
	 * @see {@link https://www.papaparse.com/}
	 *
	 * NOTE: This has reverted code here! This should be removed once bugs are fixed
	 */
	parseCsv: function (value, options) {
		var strData = value = value || '';
		options = options || {};
		var strDelimiter = options.delimiter;
		if (!strData) return [];

		// Check to see if the delimiter is defined. If not,
		// then default to comma.
		strDelimiter = (strDelimiter || ",");

		// Create a regular expression to parse the CSV values.
		/* smarter tab delimited parsing
		 http://stackoverflow.com/questions/1293147/javascript-code-to-parse-csv-data */
		var objPattern = new RegExp((
			// Delimiters.
			"(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

			// Quoted fields.
			"(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

			// Standard fields.
			"([^\"\\" + strDelimiter + "\\r\\n]*)" +

			// Standard fields with quotes inside.
			"([^\\" + strDelimiter + "\\r\\n]*))"
		), "gi");

		// Create an array to hold our data. Give the array
		// a default empty first row.
		var arrData = [[]];

		// Create an array to hold our individual pattern
		// matching groups.
		var arrMatches = null;

		// drop quotes from lines that have an odd number of them
		var strLines = strData.split("\n");
		for (var l = 0; l < strLines.length; l++) {
			if (l === 0) {
				var dlcount = (strLines[l].match(new RegExp(strDelimiter, "g")) || []).length;
			}
			var qtcount = (strLines[l].match(/"/g) || []).length;
			var linecount = (strLines[l].match(new RegExp(strDelimiter, "g")) || []).length;
			var nextlinecount = 0;
			var prevlinecount = 0;
			if (l < (strLines.length - 1) && l > 0) {
				prevlinecount = (strLines[l - 1].match(new RegExp(strDelimiter, "g")) || []).length;
				nextlinecount = (strLines[l + 1].match(new RegExp(strDelimiter, "g")) || []).length;
			}
			if (qtcount > 0 && !(qtcount % 2 == 0) && dlcount === linecount && dlcount === nextlinecount && dlcount === prevlinecount) {
				var noquotes = strLines[l].replace(/"/g, '');
				console.log('replaced ' + strLines[l] + ' with ' + noquotes);
				strLines[l] = noquotes;
			}
		}
		strData = strLines.join("\n");


		// Keep looping over the regular expression matches
		// until we can no longer find a match.
		while (arrMatches = objPattern.exec(strData)) { // jshint ignore:line

			// Get the delimiter that was found.
			var strMatchedDelimiter = arrMatches[1];

			// Check to see if the given delimiter has a length
			// (is not the start of string) and if it matches
			// field delimiter. If id does not, then we know
			// that this delimiter is a row delimiter.
			if (
				strMatchedDelimiter.length &&
				(strMatchedDelimiter != strDelimiter)
			) {

				// Since we have reached a new row of data,
				// add an empty row to our data array.
				arrData.push([]);

			}

			// Now that we have our delimiter out of the way,
			// let's check to see which kind of value we
			// captured (quoted or unquoted).
			var strMatchedValue;

			if (arrMatches[2]) {
				// We found a quoted value. When we capture
				// this value, unescape any double quotes.
				strMatchedValue = arrMatches[2].replace(
					new RegExp("\"\"", "g"),
					"\""
				);

			} else {

				// We found a non-quoted value.
				strMatchedValue = arrMatches[3];

				// If the value has quotes in it - re-run the regex
				// and overwrite with the quotes-allowed version
				if (arrMatches[4]) {

					var myRe = new RegExp(
						(
							// Delimiters.
							"(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

							// Quoted fields.
							"(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

							// Standard fields with quotes inside.
							"([^\\" + strDelimiter + "\\r\\n]*))"
						),
						"gi"
					);

					var myArray = myRe.exec(arrMatches[0]);

					if (myArray[3]) {
						console.log('replaced ' + strMatchedValue + ' with ' + myArray[3]);
						strMatchedValue = myArray[3];
					}
				}
			}

			// Now that we have our value string, let's add
			// it to the data array.
			arrData[arrData.length - 1].push(strMatchedValue);
		}

		// Return the parsed data.
		return (arrData);

		// uncommented unused code gets deleted on save
		// // TODO: Bring this back
		// value = value || '';
		// options = options || {};
		// return Papa.parse(value, _.merge({
		// 	// We use tabs because most people are uploading excel files or they
		// 	// are copying rows directly in applications like Excel, Numbers, or
		// 	// Google Sheets. Those apps will generate TSVs. BatchGeo also
		// 	// creates TSV data when when you copy rows.on map pages.
		// 	delimiter: '\t',
		// 	// Disable quote characters as they can cause CSVs to break if you
		// 	// add a ". For example, say you have a column called "dimensions".
		// 	// you might have 1'5" on row 1 and 10'20" on row two. This will
		// 	// make the parser think from that column on row 1 until that column
		// 	// on row 2 is a single cell. This disables this. This means,
		// 	// however a user won't be able to copy paste raw quoted CSV text
		// 	// like "foo","bar". That will become ['"foo","bar"'].
		// 	quoteChar: false,
		// 	// If a row is completely empty drop it
		// 	skipEmptyLines: true
		// }, options)).data;
	},

	unparseCsv: function (arrData, options) {
		options = options || {};
		const strDelimiter = options.delimiter;
		// strDelimiter = (strDelimiter || ",");

		var strData = '';

		for (var r = 0; r < arrData.length; r++) {
			if (r) strData += "\n";

			var row = arrData[r];

			for (var c = 0; c < row.length; c++) {
				if (c) strData += strDelimiter;

				var col = '' + row[c];
				if (col.indexOf(strDelimiter) > -1) col = '"' + col + '"';

				strData += col;
			}
		}

		return strData;
	},

	// script fetching utilities:
	getScriptCcd: function(url, callback) {
		$.ajax({
			async: true,
			type: "GET",
			url: url,
			success: callback,
			dataType: "script",
			cache: true
		});
	},


	roundBreakpoints: function(breakpoints){
		let rounded = [];
		//find the smallest group size, as this is the upper bounds of rounding without losing groups entirely.
		let minDiff = Infinity;
		for(let i = 0; i < breakpoints.length-1; i++){
			const diff = Math.abs(breakpoints[i]-breakpoints[i+1]);
			if(diff == 0) continue;
			if(diff < minDiff) minDiff = diff;
		}

		//figure out the number of places we can round to, effectively count how many 0s we have.
		//then turn that into a number.
		const tenPow = Math.floor(Math.log10(minDiff));
		let roundingParam = Math.pow(10, tenPow);

		for(let i = 0; i < breakpoints.length; i++){
			//round using the found number
			let roundVal = Math.floor(breakpoints[i]/roundingParam) * roundingParam;
			if (tenPow < 0) {
				rounded.push(parseFloat(roundVal.toFixed(Math.abs(tenPow))));
			} else {
				rounded.push(roundVal);
			}
		}

		//if we've rounded out the start or end of the data list, include them instead.
		if(rounded[0] < breakpoints[0]) rounded[0] += roundingParam;
		if(rounded[rounded.length-1] > breakpoints[breakpoints.length-1]) roundingParam -= roundingParam;

		return rounded;
	},  
	
	//break each group into 2 at optimal point
	getBreakpoints: function(data, numClasses){
		BatchGeo.timer("start getBreakpoints() by Split");

		//might not be optimal, particularly if numClasses is not a power of 2
		if(data.length === 0) return [];
		if(numClasses > data.length){
			return data;
		}

		let groups = [];
		let setStats = getStats(data, 0, data.length-1);
		groups.push({
			sum: setStats.sum,
			squaredSum: setStats.squaredSum,
			variance: Math.abs((setStats.squaredSum-(setStats.sum*setStats.sum))/data.length),
			start: 0,
			end: data.length-1
		});

		while(groups.length < numClasses && groups[groups.length-1].variance != 0){//while we don't have our desired number
			let largest = groups.pop();//remove the worst offendor and split into two groups

			let group1Start = largest.start; //start with the first group being the entire set -1
			let group1End = largest.end-1;
			let group1Stats = getStats(data, group1Start, group1End); //might be able to construct these from the parent group, but is not a large gain
			let group1v =0;
			let bestG1v =Infinity;
			
			let group2Start = largest.end; //start with the last group just being the last element.
			let group2End = largest.end;
			let group2Stats = getStats(data, group2Start, group2End);
			let group2v =0;
			let bestG2v = Infinity;

			let best = Infinity; //initialize the scores (smaller is better)
			let tiebreaker = Infinity;
			for(let i = group1End; i >= group1Start; i--){//scan the subgroup for optimal split point
				//calculate the variances
				group1v = Math.abs((group1Stats.squaredSum - (group1Stats.sum * group1Stats.sum)/group1Stats.length));
				group2v = Math.abs((group2Stats.squaredSum - (group2Stats.sum * group2Stats.sum)/group2Stats.length));
				let currentRangeTotals = group1v + group2v;
				
				//the total sum of the variances is the score to compare with a tiebreaker on how equal the individual variances are
				if(best > currentRangeTotals || (best == currentRangeTotals && tiebreaker > Math.abs(group1Stats.variance - group2Stats.variance))){
					//if we have a better score take notes on what it is
					group1End = i;
					group2Start = i + 1;
					bestG1v = group1v;
					bestG2v = group2v;
					best = currentRangeTotals;
					tiebreaker = Math.abs(group1Stats.variance - group2Stats.variance);
				}

				//move the trial breakpoint by removing one element from group1 and adding it to group 2
				group1Stats = removeItemFromStats(group1Stats, data[i]);
				group2Stats = addItemToStats(group2Stats, data[i]);
			}

			//once we've tried all the possible breaks, use the notes to create two new groups to use
			let group1 ={
				variance: bestG1v,
				sum: group1Stats.sum,
				squaredSum: group1Stats.squaredSum,
				start: group1Start,
				end: group1End
			};
			let group2 = {
				variance: bestG2v,
				sum: group2Stats.sum,
				squaredSum: group2Stats.squaredSum,
				start: group2Start,
				end: group2End
			};

			//add the two subgroups while maintaining list order.
			groups = addGroup(groups, group1);
			groups = addGroup(groups, group2);
		}

		//once we have all the groups we want format for returns
		let returnable = groups
			.sort((a, b) => a.start - b.start)//sort by index, not by variances
			.map((a)=> data[a.start]);//return the values from data and not the indexes

		//format to match jenks outputs
		if (returnable[0] !== data[0]) returnable.unshift(data[0]); //make sure the first element is data[0]

		if (returnable[returnable.length-1] !== data[data.length-1]) returnable.push(data[data.length-1]); //make sure the last element is data[last]
		
		BatchGeo.timer("end getBreakpoints() by Split");
		return returnable;

		function addGroup(groups, group){
			//adds to the groups while maintaining high to low variance ordering
			for (let i = 0; i < groups.length; i++) {
				if (group.variance < groups[i].variance) {
					groups.splice(i, 0, group);
					return groups;
				}
			}
			groups.push(group);
			return groups;
		}

		function removeItemFromStats(stats, item){
			//removes an item from a "group"
			stats.sum -= item;
			stats.squaredSum -= item*item;
			stats.length--;
			return stats;
		}
		function addItemToStats(stats, item){
			//adds an item to a "group"
			stats.sum += item;
			stats.squaredSum += item*item;
			stats.length++;
			return stats;
		}

		function getStats(data, start, finish) {
			//generates a "group" given a data set and a range
			let squaredSum = 0;
			let sum = 0;
			for (let i = start; i <= finish; i++) {
				let val = data[i];
				squaredSum += val * val;
				sum += val;
			}

			return {
				//value and sum are the two maintained values for groups
				//variance would be in here but given it's dependent on group size we'd need to record multiple values to maintain it
				//calculating it from these values is doable at runtime without a performance hit though
				sum: sum,
				squaredSum: squaredSum,
				length: finish+1-start,
			}
		}
	},

	outlierHandler: function (alias) {

		var sendLog = async function(url){
			return $.ajax({
					type: "POST",
					url: encodeURI(url),
					success: function (results) {
						return results
					},
					
					dataType: "text",
					cache: false
			}).catch(console.error)
			
		}

		var logOutliers = async function(outliers, alias){
			try{
				const urls = outliers.map((outlier) => {
					if(outlier.isMeta){
						return `/api/log/?message=Outlier Detected: alias(${alias}) detected(${outlier.detected}) rate(${outlier.rate}%) avgDistance(${outlier.avgDist}) outlierDistanceFactor(${outlier.outlierAvgDistFactor})`;
					}
					return `/api/log/?message=Outlier Detected: alias(${alias}) address(${outlier.data}) row(${outlier.id})`;
				});
				//send requests
				const logs = urls.map(url => sendLog(url))
				//await all responses to fulfuill/reject
				const results = await Promise.all(logs)
				//return status codes
				return results;
			}catch(error){
				console.error(error);
				throw error;
			}
		}

		const AppState = BatchGeoStore.getState().App;
		//if outliers exist, log them with the map alias
		if(AppState && AppState.outliers && AppState.outliers.length > 0){
			logOutliers(AppState.outliers, alias)
				.then(response => {
					console.log('outliers have been logged\n', response)
				})
				.catch(console.error)

			//clear the outliers from current state
			BatchGeoStore.dispatch({
				type: 'CLEAR_OUTLIERS'
			})
		}
	}
};

})(window);
