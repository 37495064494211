(function (window) {
	var tabletSize = 1024;
	var isIos = /(iPad|iPhone|iPod|Windows Phone OS)/gi.test(navigator.userAgent);
	var isAndroid = /(android)/gi.test(navigator.userAgent);
	var isSamsungSmartTV = /(SMART-TV)(.+?)(SamsungBrowser)/gi.test(navigator.userAgent);

	window.BatchGeoDevices = {
		getDimensions: function () {
			return [screen.width, screen.height];
		},
		getLargestSide: function () {
			return Math.max.apply(window,  this.getDimensions());
		},
		is: function (query) {
			switch (query) {
				case 'ios':
					return isIos;
				case 'iosapp':
					return isIos && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.nativeApp;
				case 'android':
					return isAndroid;
				case 'mobile':
					return isIos || isAndroid;
				case 'phone':
					return (isIos || isAndroid) && this.getLargestSide() < tabletSize;
				case 'tablet':
					return (isIos || isAndroid) && this.getLargestSide() >= tabletSize;
				case 'samsungsmarttv':
					return isSamsungSmartTV;
			}
		}
	}
})(window);
