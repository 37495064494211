/**
 * BatchGeoAccessView handles logic related to forbidden maps differing between the iOS app and other clients.
 *
 * @return {object} It is immediately invoked and only returns one public method,
 * `render` which renders the necessary content.
 */
(function(window){
	var BatchGeoAccessView = function () {
		this.isIOSApp = window.BatchGeoDevices.is('iosapp');
		this.strings = window.BatchGeoStrings.getStringsForComponent('BatchGeoAccessView');
	}

	BatchGeoAccessView.prototype._renderAccessDenied = function () {
		var $message = $('<p />').text(this.strings.get('DEFAULT_MESSAGE'));
		var $button = $('<a href="/">').text(this.strings.get('DEFAULT_BUTTON_TEXT'));

		// render denied view on main access denied body
		$(".access-denied").append([
			$message,
			$button
		]);
	}

	BatchGeoAccessView.prototype._renderIOSAccessDenied = function () {
		var $message = $('<p />').html(this.strings.get('IOS_APP_MESSAGE'));

		// render denied view on main access denied body
		$(".access-denied").append($message);
	}

	BatchGeoAccessView.prototype.render = function () {
		if (this.isIOSApp) {
			this._renderIOSAccessDenied();
		} else {
			this._renderAccessDenied();
		}
	}

	window.BatchGeoAccessView = BatchGeoAccessView;
})(window);
